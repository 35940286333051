// Logout.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

function Logout() {
    const navigate = useNavigate();

    useEffect(() => {
        async function signOut() {
            try {
                await Auth.signOut();
                navigate('/'); // Redirect to home after logout
            } catch (error) {
                console.error('Error signing out:', error);
            }
        }

        signOut();
    }, [navigate]);

    return <div>Logging out...</div>;
}

export default Logout;
