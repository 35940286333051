import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

function LoginPage() {
    const navigate = useNavigate();

    useEffect(() => {
        // Attempt to fetch the current user's info
        Auth.currentAuthenticatedUser()
            .then(user => {
                // console.log('User:', user);
                navigate('/dashboard');  // Redirect to dashboard after successful login
            })
            .catch(err => {
                console.error('Error during login:', err);
                // Handle error, maybe redirect to an error page or show a message
            });
    }, [navigate]);

    return <div>Logging you in...</div>;
}

export default LoginPage;
