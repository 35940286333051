// src/theme.js
import { createTheme } from "@aws-amplify/ui-react";
import { studioTheme } from './ui-components'; // Adjust the path if necessary

const updatedTheme = createTheme({
    name: "my-theme-updates",
    tokens: {
        components: {
            button: {
                primary: {
                    backgroundColor: {
                        value: "#b71c1c"
                    },
                },
            },
        },
    },
}, studioTheme);

export default updatedTheme;
