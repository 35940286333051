//App.js
import React, {useState, useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import LoginPage from './LoginPage';
import Logout from './Logout';
import NotFound from './NotFound';
import logo from './assets/logo.svg';
import './styles/App.css';
import {Amplify, Auth} from 'aws-amplify';
import { DataStore } from '@aws-amplify/datastore';
import config from './aws-exports';
import {StyledLoginButton, StyledLogoutButton} from './styles/AuthStyles';
import {PrivateNoteCreateForm, TaskCreateForm} from "./ui-components";
import {ThemeProvider} from "@aws-amplify/ui-react";
import TaskList from "./ui-components/custom/TaskList";
import PrivateNoteList from "./ui-components/custom/PrivateNoteList";
import updatedTheme from './theme';

const customisedDomainConfig = {
    ...config,
    oauth: {
        ...config.oauth,
        domain: `${process.env.REACT_APP_AUTH_DOMAIN}`,
        redirectSignIn: `${process.env.REACT_APP_LOGIN_REDIRECT_URL}`,
        redirectSignOut: `${process.env.REACT_APP_LOGOUT_REDIRECT_URL}`,
    }
};

Amplify.configure(customisedDomainConfig);

const startDataStore = async () => {
    try {
        await Auth.currentAuthenticatedUser();
        await DataStore.start();
    } catch (error) {
        console.error('DataStore not started: No current user.');
    }
};

function WelcomeScreen() {
    const handleLogin = async () => {
        Auth.federatedSignIn();
        await startDataStore();
        console.log('User logged in and DataStore sync started');
    };

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>
                <p>Wallet Portal</p>
                <StyledLoginButton variant="contained" color="primary" onClick={handleLogin}>
                    Login
                </StyledLoginButton>
            </header>
        </div>
    );
}

function Dashboard() {
    const handleLogout = async () => {
        try {
            await DataStore.stop();
            await DataStore.clear();
            await Auth.signOut();
            console.log('User signed out and DataStore cleared');
        } catch (error) {
            console.log('Error signing out: ', error);
        }
    };

    return (
        <ThemeProvider theme={updatedTheme}>
            <div className="App">
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo"/>
                    <TaskList/>
                    <TaskCreateForm
                        onSubmit={(fields) => {
                            // Example function to trim all string inputs
                            const updatedFields = {}
                            Object.keys(fields).forEach(key => {
                                if (typeof fields[key] === 'string') {
                                    updatedFields[key] = fields[key].trim()
                                } else {
                                    updatedFields[key] = fields[key]
                                }
                            })
                            return updatedFields
                        }}
                    />

                    <PrivateNoteList/>
                    <PrivateNoteCreateForm
                        onSubmit={(fields) => {
                            // Example function to trim all string inputs
                            const updatedFields = {}
                            Object.keys(fields).forEach(key => {
                                if (typeof fields[key] === 'string') {
                                    updatedFields[key] = fields[key].trim()
                                } else {
                                    updatedFields[key] = fields[key]
                                }
                            })
                            return updatedFields
                        }}
                    />

                    <StyledLogoutButton variant="contained" color="secondary" onClick={handleLogout}>
                        Logout
                    </StyledLogoutButton>
                </header>
            </div>
        </ThemeProvider>
    );
}

function App() {
    const [user, setUser] = useState(null);
    const [isAuthCheckComplete, setIsAuthCheckComplete] = useState(false); // New state

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => {
                setUser(user);
                startDataStore().then();
            })
            .catch(() => setUser(null))
            .finally(() => setIsAuthCheckComplete(true)); // Set to true after check
    }, []);

    if (!isAuthCheckComplete) {
        return <div>Loading...</div>; // Or some loading indicator
    }    return (
        <Router>
            <Routes>
                <Route path="/login/" element={<LoginPage/>}/>
                <Route path="/logout/" element={<Logout/>}/>
                <Route path="/dashboard" element={user ? <Dashboard/> : <Navigate to="/"/>}/>
                <Route path="/" element={<WelcomeScreen/>}/>
                <Route path="*" element={<NotFound/>}/> {/* This acts as the 404 catch-all */}
            </Routes>
        </Router>
    );
}

export default App;
