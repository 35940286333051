// PrivateNoteList.jsx
import React from 'react';
import {PrivateNote} from '../../models';
import { PrivateNoteUpdateForm } from '../index';
import '../../styles/App.css';
import useAuthenticatedDataStore from "../hooks/useAuthenticatedDataStore"; // Import the styles

const PrivateNoteList = () => {
    const [privateNotes, isUserAuthenticated]  = useAuthenticatedDataStore(PrivateNote);

    if (!isUserAuthenticated) {
        return <div>Please log in to view tasks.</div>;
    }

    return (
        <div className="task-card-container">
            <h3>PrivateNotes</h3>
            {privateNotes.map(privateNote => (
                <div key={privateNote.id} className="task-card">
                    <h5 className="task-card-title">{privateNote.content}</h5>
                    <PrivateNoteUpdateForm privateNote={privateNote} />
                </div>
            ))}
        </div>
    );
};

export default PrivateNoteList;
