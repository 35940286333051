// hooks/useAuthenticatedDataStore.js
import { useState, useEffect, useCallback } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { Auth } from '@aws-amplify/auth';

const useAuthenticatedDataStore = (model) => {
    const [data, setData] = useState([]);
    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

    const fetchData = useCallback(async () => {
        try {
            const result = await DataStore.query(model);
            setData(result);
        } catch (err) {
            console.error(`Error fetching data for model ${model}`, err);
        }
    }, [model]); // Add fetchData dependencies if any

    const checkUserAuthentication = useCallback(async () => {
        try {
            await Auth.currentAuthenticatedUser();
            setIsUserAuthenticated(true);

            await DataStore.start();
            const subscription = DataStore.observe(model).subscribe(fetchData);
            await fetchData(); // Initial fetch

            return () => subscription.unsubscribe();
        } catch (err) {
            console.error('User not authenticated:', err);
            setIsUserAuthenticated(false);
        }
    }, [model, fetchData]); // Now includes fetchData

    useEffect(() => {
        checkUserAuthentication();
    }, [checkUserAuthentication]);

    return [data, isUserAuthenticated];
};

export default useAuthenticatedDataStore;
