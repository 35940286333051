// TaskList.jsx
import React from 'react';
import { Task } from '../../models';
import { TaskUpdateForm } from '../index';
import '../../styles/App.css';
import useAuthenticatedDataStore from '../hooks/useAuthenticatedDataStore'; // Adjust the path as needed

const TaskList = () => {
    const [tasks, isUserAuthenticated] = useAuthenticatedDataStore(Task);

    if (!isUserAuthenticated) {
        return <div>Please log in to view tasks.</div>;
    }

    return (
        <div className="task-card-container">
            <h3>Tasks</h3>
            {tasks.map(task => (
                <div key={task.id} className="task-card">
                    <h5 className="task-card-title">{task.title}</h5>
                    <p className="task-card-description">{task.description}</p>
                    <p className="task-card-status">{task.status}</p>
                    <TaskUpdateForm task={task} />
                </div>
            ))}
        </div>
    );
};

export default TaskList;
