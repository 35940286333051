// src/styles/AuthStyles.js
import styled from 'styled-components';
import { Button } from '@mui/material';

export const StyledLoginButton = styled(Button)`
  /* Custom styles for login button */
`;

export const StyledLogoutButton = styled(Button)`
  /* Custom styles for logout button */
`;
